import React from 'react'
import WebdesignPagesHero from '../../subcomponents/WebdesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const WebCMS = () => {
  return (
    <div>
        <WebdesignPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>İçerik yönetim sistemi (CMS)</h1>
      <ContentHolder2>
          <ContentWrapper>
          İçerik yönetim sistemi (CMS), bir internet sitesinin içeriğini yönetmek için kullanılan bir yazılımdır.
CMS, site sahiplerine ve yöneticilere, site içeriğini düzenleme, güncelleme ve yayınlama işlemlerini
kolaylaştıran bir araç sağlar.CMS, genellikle bir veritabanı ve bir web arayüzü kullanır. Site sahipleri ve
yöneticiler, web arayüzünden site içeriğine erişir ve içeriği düzenler, günceller ve yayınlar. Bu sayede,
site içeriğini düzenleme ve güncelleme işlemleri kolay hale gelir ve zaman tasarrufu sağlar. CMS,
çeşitli içerik türlerini destekler. Örneğin, metin, resim, video veya ses dosyaları gibi içerikleri
yönetebilir. Ayrıca, CMS, site içeriğini etiketleme ve kategorileme gibi işlemleri de destekler, bu
sayede site içeriğinin daha kolay aranmasını sağlar. CMS, birçok farklı amaç için kullanılabilir. Örneğin,
bir e-ticaret sitesi için, CMS sayesinde ürünlerin listelenmesi, ürünlerin resimlerinin yüklenmesi ve
ürün açıklamalarının güncellenmesi gibi işlemler kolay hale gelir. Ayrıca, bir blog sitesi için de CMS,
yazıların yazılması, yayınlanması ve etiketleme gibi işlemleri kolaylaştırır.
<br/>
<br/>
CMS, genellikle bir veritabanı ve bir web arayüzü kullanır ve site sahipleri ve yöneticiler, web
arayüzünden site içeriğine erişir ve içeriği düzenler, günceller ve yayınlar. Bu sayede, site içeriğini
düzenleme ve güncelleme işlemleri kolay hale gelir ve zaman tasarrufu sağlar.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default WebCMS