import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const PosterDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Poster Tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Posterler, genellikle sokaklarda, otobüs duraklarında veya yoğunluk yaşanan bölgelere asılır ve
müşterilere ürünler hakkında bilgi edinme imkanı sağlar.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default PosterDesign


