import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements'
const SidebarElements = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon  />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/" onClick={toggle}> Anasayfa </SidebarLink>
                    <SidebarLink to="/aboutus" onClick={toggle}> Hakkımızda </SidebarLink>
                    <SidebarLink to="/services" onClick={toggle}> Hizmetlerimiz </SidebarLink>
                    <SidebarLink to="/contact" onClick={toggle}> İletişim </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute href="/contact">Teklif Al</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarElements