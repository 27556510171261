import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const InfoGraphics = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>İnfografik Videoları</h1>
      <ContentHolder2>
          <ContentWrapper>
            Infografik videolar, bir konu hakkında bilgi vermek için kullanılan hareketli grafiklerden oluşan
            videolardır. Bu videolar, genellikle bir konu hakkında anlaşılır ve görsel olarak etkileyici bir şekilde
            bilgi vermek amacıyla kullanılır. Infografik videolar, konuyu anlatırken sıkıcı ve monoton bir dille
            kalmayıp, görsel unsurları da kullanarak daha etkili bir şekilde anlatılmasına yardımcı olur. Bu sayede,
            izleyiciler konuyu daha iyi anlayabilir ve kalıcı bir iz bırakabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default InfoGraphics