import React from 'react'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import ProductionHero from '../../subcomponents/ProductionPagesHero'
import styled from 'styled-components'
import { ServicesSubPagesContentContainer } from './AdMovie'

const ProductMovie = () => {
  return (
    <div>
    <ProductionHero/>
    <ServicesSubPagesContentContainer>
    <h1 style={{marginTop:"45px"}}>Ürün tanıtım videoları</h1>
    <ContentHolder2>
        <ContentWrapper>
        Ürün tanıtım videoları, firmanızın ürünlerini doğru ve etkili bir şekilde tanıtmak için kullanılır. Bu
        sayede, potansiyel müşterilerinizin firmanızın ürünleri hakkında daha detaylı bilgi edinmelerine
        yardımcı olur.
        </ContentWrapper>
    </ContentHolder2>
    </ServicesSubPagesContentContainer>
    <Footer/>
    </div>
  )
}

export default ProductMovie