import React, {useState} from 'react'
import {
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    GeneralButtonHero
} from "../HeroSection/HeroElements"
import styled  from 'styled-components'
import HeroImg from "../../assets/images/service_hero.jpg"
export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    padding : 0 30px;
    height: 35vh;
    position: relative;
    z-index: 1;
    margin-top:-50px;
    :before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35vh;
    overflow: hidden;
`
export const VideoBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`
export const HeroContent = styled.div`
    z-index: 3;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content:center;
`
const ProductionHero = () => {
    const [hover, setHover] = useState(false) 

    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg autoPlay loop muted src={HeroImg}>
        </VideoBg>
        </HeroBg>
        <HeroContent>
            <HeroH1>Prodüksiyon Hizmetleri</HeroH1>
        </HeroContent>
    </HeroContainer>
  )
}

export default ProductionHero