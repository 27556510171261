import React from 'react'
import styled from "styled-components"
import { GeneralButtonHero } from '../HeroSection/HeroElements'


export const HomeCallToActionContainer = styled.div`
    width: 100%;
    background:  ${({color}) => (color)}; 
    padding: 80px 0px 90px 0px;
    z-index: 100;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;

`

export const  HomeCallToActionWrapper  = styled.div`
    max-width:650px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
`

export const CallToActionTitle = styled.h3`
    font-size: 25px;
`

export const CallToActionMain = styled.h1`
    font-size: 30px;

`

const HomeCallToAction = ({clr}) => {
  return (
    <HomeCallToActionContainer color={clr}>
        <HomeCallToActionWrapper>
            <CallToActionTitle>Farklı Hizmetlerimizden Yararlanın</CallToActionTitle>
            <CallToActionMain>Animasyon, film, grafik tasarım ve sosyal medya yönetimi gibi farklı alanlarda hizmet veriyoruz.
Hemen bizimle
iletişime geçin ve değişiminizi farkedin.</CallToActionMain>
            <GeneralButtonHero to="/contact" primary={true} style={{background:"#ff3636", width: "45%", color:"white"}}>
                    İletişime Geç
            </GeneralButtonHero>
        </HomeCallToActionWrapper>
    </HomeCallToActionContainer>
  )
}

export default HomeCallToAction