import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const JournalDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Dergi tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Dergi tasarımı, bir derginin içeriğinin düzenlenmesi, görsel olarak tasarımı ve basımı sürecidir. Dergi
tasarımı, bir derginin içeriğini anlamlı ve etkili bir şekilde sunmak için önemlidir ve dergi tasarımı
çalışmalarında görsel öğelerin etkili bir şekilde kullanılması önemlidir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default JournalDesign


