import React from 'react'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import ProductionHero from '../../subcomponents/ProductionPagesHero'
import styled from 'styled-components'
import { ServicesSubPagesContentContainer } from './AdMovie'
const ProductPhoto = () => {
  return (
    <div>
    <ProductionHero/>
    <ServicesSubPagesContentContainer>
    <h1 style={{marginTop:"45px"}}>Ürün görsel çekimi</h1>
    <ContentHolder2>
        <ContentWrapper>
        Ürün görsel çekimi, firmanızın ürünlerini doğru bir şekilde tanıtmak ve müşterilerinizin dikkatini
        çekmek için önemlidir. Bu nedenle, profesyonel bir prodüksiyon şirketi ile çalışarak, kaliteli ve etkili
        ürün görsel çekimleri yapmanız önerilir. Ayrıca, ürün görsel çekimi sayesinde firmanızın ürünlerini
        daha iyi anlatabilir ve potansiyel müşterilerinizin dikkatini çekebilirsiniz.
        </ContentWrapper>
    </ContentHolder2>
    </ServicesSubPagesContentContainer>
    <Footer/>
    </div>
  )
}

export default ProductPhoto