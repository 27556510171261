import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const ThreeDPlaceDesign = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>3D mekan hazırlama hizmeti</h1>
      <ContentHolder2>
          <ContentWrapper>
            3D mekan hazırlama hizmeti, bir mekanın veya ortamın bilgisayar ortamında üç boyutlu bir modelinin
            çiziminin yapılmasıdır. Bu sayede, mekan veya ortamın gerçek hayattaki görünümüne benzer bir
            model oluşturulabilir ve bu model üzerinde değişiklikler yaparak farklı senaryolarda kullanılabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default ThreeDPlaceDesign
