import React from 'react'
import WebdesignPagesHero from '../../subcomponents/WebdesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const WebDesignMain = () => {
  return (
    <div>
        <WebdesignPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}> Web sitesi tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Web sitesi tasarımı, bir internet sitesinin görsel tasarımının ve işlevselliğinin tasarımı sürecidir. Web
        sitesi tasarımı, birçok farklı aşamayı içerir ve genellikle aşağıdaki adımları içerir:
<br/>
<br/>
        <ol>1-İş gereksinimlerinin belirlenmesi: İlk olarak, web sitesinin amacı ve hedef kitlesi belirlenir. Bu
        aşama, web sitesinin işlevselliğini ve tasarımını belirleyen önemli bir etkendir.</ol>
        <ol>2-Görsel tasarım: Görsel tasarım aşamasında, web sitesinin görünüşü ve tasarımı oluşturulur. Bu
        aşamada, web sitesinin renkleri, tipografisi, logo ve diğer görsel öğeleri belirlenir.</ol>
        <ol>3-İşlevsellik: İşlevsellik aşamasında, web sitesinin hangi işlevlerin gerçekleştirileceği belirlenir. Bu
        aşamada, web sitesinin içeriği oluşturulur ve siteyi kullanımı kolay hale getiren öğeler eklenir.        
        Örneğin, bir arama özelliği, bir iletişim formu veya bir ürün kataloğu gibi işlevler eklenebilir.</ol>
        <ol> 4-Kodlama: Kodlama aşamasında, web sitesinin tasarımı ve işlevselliği gerçekleştirilebilir hale getirilir.
        Bu aşamada, web sitesinin tasarımı için gereken HTML, CSS ve JavaScript gibi kodlar yazılır.</ol>
        <ol> 5-Test etme: Test etme aşamasında, web sitesinin işlevselliği ve performansı test edilir. Bu aşamada,
        web sitesinin çeşitli tarayıcılarda nasıl göründüğü ve çalıştığı test edilir ve varsa hatalar düzeltilir.</ol>
        <ol>6-Yayınlama: Yayınlama aşamasında, web sitesi kullanıma sunulur. Bu aşamada, web sitesi bir web
        sunucusuna yüklenir ve internete yayınlanır.</ol>
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default WebDesignMain