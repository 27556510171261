import React from 'react'
import styled from "styled-components"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GeneralButtonHero } from '../HeroSection/HeroElements';
import { FaPlay } from 'react-icons/fa';
import Logo1 from "../../assets/images/1.png"
import Logo2 from "../../assets/images/2.png"
import Logo3 from "../../assets/images/3.png"
import Logo4 from "../../assets/images/4.png"
import Logo5 from "../../assets/images/5.png"
import Logo6 from "../../assets/images/6.png"
import Logo7 from "../../assets/images/7.png"
import Logo8 from "../../assets/images/8.png"

export const HomeAllMainContainer = styled.div`
    width: 100%;
    
`
export const HomeAllOurVideosWrapper = styled.div`
    width: 100%;
    background: #161617;
    display:flex;
    padding-bottom: 40px;
    align-items:center;
    justify-content:top;
    flex-direction:column;

`
export const HomeAllOurVideosHeading = styled.div`
    max-width: 550px;
    padding-top: 45px;

`
export const HomeAllOurVideosHeadingH3 = styled.h3`
    color:white;
    text-align:center;
    font-size:45px;
`
export const HomeInfoVideoMiniClip = styled.img`
    display: block;
    position: relative;
    border: 10px solid #000;
    box-shadow: 20px 20px 50px rgb(0 0 0 / 40%);
    object-fit:contain;
    max-width: 550px;
    @media screen and (max-width:1768px){
        max-width: 450px;
    }
    @media screen and (max-width:1468px){
        max-width: 350px;
    }
    @media screen and (max-width:1120px){
        max-width: 350px;
    }
    @media screen and (max-width:964px){
        max-width: 300px;
    }

`

export const HomeMiniClipContainer = styled.div`
    position: relative;
    max-width: 400px;

`

export const Highlight = styled.div`
    height: 160px;
    width: 160px;
    background: ${({color}) => (color)};
`


const HomeAllOurVideos = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,       responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 1096,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          },
        },            
        // {
        //   breakpoint: 560,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     infinite: true,
        //   },
       // }
      ]
    };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4500,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 1096,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
       
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          },
        },            
        // {
        //   breakpoint: 560,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     infinite: true,
        //   },
       // }
      ]
  };
  return (
    <HomeAllMainContainer>
        <HomeAllOurVideosWrapper>
            <HomeAllOurVideosHeading>
                <HomeAllOurVideosHeadingH3>
                    Çalışmalarımız
                </HomeAllOurVideosHeadingH3>
            </HomeAllOurVideosHeading>
            <div style={{width: "100%"}}>
            <Slider {...settings}>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo1} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo2} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo4} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo5} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
          </Slider>
            </div>
            <div style={{width: "100%"}}>
            <Slider {...settings2}>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo3} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo6} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo7} />
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
              <HomeMiniClipContainer>
                  <HomeInfoVideoMiniClip src={Logo8}/>
                  {/* <GeneralButtonHero to="/solutions" primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                          Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                  </GeneralButtonHero> */}
              </HomeMiniClipContainer>
          </Slider>
            </div>
            
   
        </HomeAllOurVideosWrapper>
    </HomeAllMainContainer>
  )
}

export default HomeAllOurVideos