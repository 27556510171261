import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const BannerEngDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Banner tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Banner tasarımı, bir şirketin ürünlerini ve hizmetlerini tanıtmak için kullanılan reklam aracıdır.
Bannerlar, genellikle internet sitelerinin ana sayfalarında veya diğer web sayfalarında görüntülenir ve
müşterilere ürünler hakkında bilgi edinme imkanı sağlar. Banner tasarımı, ürünlerin görsel olarak
düzenlenmesi, metinlerin yazılması ve ürünlerin tanıtılması gibi farklı aşamaları içerir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default BannerEngDesign


