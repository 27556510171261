import React from 'react'
import { FaLocationArrow, FaMailBulk, FaPhone, FaWhatsapp } from 'react-icons/fa'
import styled from "styled-components"
import { ContactText, TextHolder } from '.'
import Footer from '../../subcomponents/Footer'

export const PageWrapper = styled.div`
    display:flex;
    flex-direction:column;
`
export const PageContainer = styled.div`
    display:flex;
    flex-direction:column;
    min-height: 80vh;
    align-items:center;
    justify-content:start;
    width: 100%;
    background:#f5f5f7;
`
export const PageHeader = styled.h1`
    font-size:44px;
`
export const PageInternalContainer = styled.div`
    display:flex;
    gap:15px;
    flex-direction:row;
    @media screen and (max-width:900px){
        flex-direction:column;
    }

`

export const PageInternalInformationHolderBox = styled.div`
    height: 550px;
    width:550px;
    border-radius:15px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    background:white;
    padding:15px;
    @media screen and (max-width:1196px){
        height: 400px;
        width:400px;
    }
    @media screen and (max-width:458px){
        height: 310px;
        width:310px;
    }
`




const ContactV2 = () => {
  return (
    <PageWrapper>
        <PageContainer>
        <PageHeader style={{textAlign:"center"}}>Bizimle İletişime Geçin</PageHeader>
        <PageInternalContainer>
            <PageInternalInformationHolderBox>
                <b style={{fontSize:"25px"}}>İletişim Bilgileri</b>
                <TextHolder>
                <ContactText>
                   <FaLocationArrow/> 1403. Cd. No 10/10 Odabaşı Apt. Balgat Ankara
                </ContactText>
                <ContactText>
                    <FaPhone/> +90 312 431 0705
                </ContactText>
                <ContactText>
                    <FaWhatsapp/> +90 539 585 2078
                </ContactText>
                <ContactText>
                    <FaMailBulk/> info@yapimeviproduksiyon.com
                </ContactText>
                </TextHolder>
            </PageInternalInformationHolderBox>
            <PageInternalInformationHolderBox>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d765.1134349045877!2d32.821522729235596!3d39.908861027430056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f45d647debb%3A0x507c1cb080f6caa9!2sBalgat%2C%201403.%20Sk.%20No%3A10%2F10%2C%2006520%20%C3%87ankaya%2FAnkara!5e0!3m2!1sen!2str!4v1668380670905!5m2!1sen!2str"style={{border:0, width:"100%", height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </PageInternalInformationHolderBox>
        </PageInternalContainer>
        </PageContainer>
        <Footer/>
    </PageWrapper>
  )
}

export default ContactV2