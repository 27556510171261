import React from 'react'
import styled from "styled-components"
import { GeneralButtonHero } from '../HeroSection/HeroElements'

export const HomeDetailsContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:50px;
    margin-bottom:50px;


`

export const HomeDetailsWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:row;
    gap:50px;
    @media screen and (max-width: 965px){
        flex-direction:column;
    }
` 

export const HomeDetailInfoBox = styled.div`
    min-height: 410px;
    width: 650px;
    display:flex;
    background:#F2F2F2;
    border-radius: 10px;
    flex-direction:column;
    padding: 50px;

    @media screen and (max-width: 470px){
        width: 260px;
    }
    @media screen and (max-width: 370px){
        width: 220px;
    }
`

export const HomeDetailInfoBoxTop = styled.h3`
line-height:20px;
font-size:23px;
color:#ff3636;
`
export const HomeDetailInfoBox2ndTop = styled.h2`
    font-size:20px
`


const HomeDetails = () => {
  return (
    <HomeDetailsContainer>
        <HomeDetailsWrapper>
            <HomeDetailInfoBox>
                <HomeDetailInfoBoxTop>
                Vizyonumuz
                </HomeDetailInfoBoxTop>
                <HomeDetailInfoBox2ndTop>
                <ul>
                    <li>Animasyon, film, grafik tasarım ve sosyal medya yönetimi alanlarında lider olmak.</li>
                    <li>Müşterilerimize en yüksek kalitede hizmetler sunarak, onların ihtiyaçlarını en iyi şekilde karşılamak.</li>
                    <li>Sürekli gelişmeyi hedefleyerek, sektörde en yenilikçi ve en iyi çözümleri sunmak.</li>

                </ul>
                    
                </HomeDetailInfoBox2ndTop>
                {/* <GeneralButtonHero to="/solutions" primary={true} style={{background:"#ff3636", width: "45%", color:"white"}}>
                        Teklif Al
                </GeneralButtonHero> */}
            </HomeDetailInfoBox>

            <HomeDetailInfoBox>
                <HomeDetailInfoBoxTop>
                        Misyonumuz
                    </HomeDetailInfoBoxTop>
                    <HomeDetailInfoBox2ndTop>
                    <ul>
                    <li>Müşteri memnuniyetini ön planda tutarak, onların ihtiyaçlarını en iyi şekilde karşılamak için sürekli araştırma yapmayı ve gelişmeyi hedefliyoruz.</li>
                    <li>Sektörde yenilikleri takip ederek, en son teknolojileri kullanarak müşterilerimize en iyi hizmeti sunmayı amaçlıyoruz.</li>
                    <li>İş ortaklarımızla uzun soluklu ve güvenilir iş birlikleri kurarak, birlikte büyümeyi hedefliyoruz.</li>
                    <li>Çalışanlarımıza önem vererek, onların yeteneklerini en iyi şekilde kullanmaları için eğitimler vererek gelişmelerini hedefliyoruz.</li>

                </ul>
                    



                    </HomeDetailInfoBox2ndTop>
            </HomeDetailInfoBox>
            
        </HomeDetailsWrapper>
    </HomeDetailsContainer>
  )
}

export default HomeDetails