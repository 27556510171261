import React from 'react'
import Footer from '../../subcomponents/Footer'
import HeroSection from '../../subcomponents/HeroSection'
import HomeAllOurVideos from '../../subcomponents/HomeAllOurVideos'
import HomeCallToAction from '../../subcomponents/HomeCallToAction'
import HomeDetails from '../../subcomponents/HomeDetails'
import HomeFirstInfo from '../../subcomponents/HomeFirstInfo'
import ReferancesComponent from '../../subcomponents/ReferancesComponent'

const HomePage = () => {
  return (
    <React.Fragment>
        <HeroSection/>
        <HomeFirstInfo/>
        <HomeDetails/>
        <HomeAllOurVideos/>
        <HomeCallToAction clr={"#FFF;"}/>
        <ReferancesComponent/>
        <Footer/>
    </React.Fragment>
  )
}

export default HomePage