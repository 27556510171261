import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import Logo from '../../assets/images/black_logo.png'


import styled from "styled-components"
import { Link } from "react-router-dom"

export const ImgLogo = styled.img`
    height:20px;
    object-fit: contain;
`

export const FooterContainer = styled.footer`
    background-color: #F5F5F7;

`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display:flex;
    justify-content: center;
    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`
export const FooterLinksWrapper = styled.div`
    display:flex;
    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`
export const FooterLinkItems = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #515154;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;

    }
`
export const FooterLinklTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
    color: #000;
`
export const FooterLink = styled(Link)`
    color: #515154;
    text-decoration:none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    &:hover{
        color:#000;
        transition: 0.3 ease-out
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%
`

export const SocialMediaWrap =styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;
    @media screen and (max-width: 820px){
        flex-direction:column
    }
`

export const Sociallogo = styled(Link)`
    color: #515154;
    justify-self: start;
    cursor:pointer;
    text-decoration:none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`

export const WebsiteRights = styled.small`
    color: #515154;
    margin-bottom: 16px
`
export const SocialIcons = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 240px
`

export const SocialIconLink = styled.a`
    color:#515154;
    font-size: 24px;
`

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    {/* <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinklTitle> HAKKIMIZDA </FooterLinklTitle>
                            <FooterLink to="/user-agreement">KULLANICI SÖZLEŞMELERİ</FooterLink>
                            <FooterLink to="/gdpr">KVKK</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinklTitle> KURUMSAL </FooterLinklTitle>
                            <FooterLink to="/career">KARİYER</FooterLink>
                            <FooterLink to="/sponsorship">SPONSORLUK</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinklTitle> UYGULAMAMIZ </FooterLinklTitle>
                            <FooterLink to="/solutions/bi">ONKASA BI</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinklTitle> YAPAY ZEKA </FooterLinklTitle>
                            <FooterLink to="/ailab">ONKASA AI LAB</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper> */}
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <Sociallogo to="/">
                            <ImgLogo src={Logo} alt="yapım evi"/>
                        </Sociallogo>
                        <WebsiteRights>YAPIM EVİ © {new Date().getFullYear()} Tüm Hakları Saklıdır</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="/" target="_blank" arial-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" arial-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" arial-label="Youtube">
                                <FaYoutube/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" arial-label="Twitter">
                                <FaTwitter/>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" arial-label="Linkedin">
                                <FaLinkedin/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
