import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const ThreeDFurniture = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>3D mobilya desteği</h1>
      <ContentHolder2>
          <ContentWrapper>
          3D mobilya desteği, bir mekan için gereken mobilyaların bilgisayar ortamında üç boyutlu modellerinin
            resmedilmesini sağlar. Bu sayede, mobilyaların gerçek hayattaki görünümüne benzer modeller
            çizilebilir ve bu modeller üzerinde değişiklikler yaparak mekanda kullanılabilir. Ayrıca, 3D mobilya
            desteği sayesinde mekanın düzenlenmesi ve mobilyaların yerleştirilmesi konusunda hizmet alınabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default ThreeDFurniture
