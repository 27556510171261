import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ServiceContentContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background:#f5f6f7;

`

export const ServicesContnetWrapper = styled.div`
    padding: 50px;
    max-width: 1600px;
    display:flex;
    flex-wrap: wrap;
    gap:15px;
    justify-content:space-evenly;
    align-items:center;
    @media screen and (max-width:504px){
        padding: 10px;
    }
`

export const ServicesContentBox = styled.div`
    display:flex;
    flex-direction:column;
    background: white;
    border-radius:10px;
    height:540px;
    width: 400px;
    align-items:center;
    @media screen and (max-width:420px){
        width: 300px;
    }
   
`

export const ServicesContentBoxHeading = styled.h1`
    text-align:center;
`
export const ServicesContentMainContent = styled.div`
    display:flex;
    flex-direction:column;

`

export const ServicesContentLink = styled(Link)`
    text-decoration:none;
    color: #000;
    text-align:center;
    min-height:35px;
    cursor:pointer;
`


const ServicesContent = () => {
  return (
    <ServiceContentContainer>
        <ServicesContnetWrapper>
        <ServicesContentBox>
                <ServicesContentBoxHeading>
                     Grafik Tasarım
                </ServicesContentBoxHeading>
                <hr style={{width:"80%"}}/>
                <ServicesContentMainContent>
                    <ServicesContentLink to="/services/graphic-design/brand-identity">
                        Kurumsal Kimlik Hizmeti
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/catalog-design">
                        Katalog Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/leaflet-design">
                        Broşür Tasarımı  
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/billboard-design">
                         Billboard Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/graphic-design/journal-design">
                         Dergi Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/flyer-design">
                         Flyer Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/graphic-design/banner-design">
                          Afiş Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/vehicle-cover-design">
                         Araç Giydirme Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/brand-identity-design">
                         Kurumsal Kimlik Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/graphic-design/logo-design">
                         Logo Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/banner-main-design">
                         Banner Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/graphic-design/poster-design">
                         Poster Tasarımı
                    </ServicesContentLink>
                </ServicesContentMainContent>
            </ServicesContentBox>
            <ServicesContentBox>
                <ServicesContentBoxHeading>
                    Prodüksiyon Hizmetleri
                </ServicesContentBoxHeading>
                <hr style={{width:"80%"}}/>
                <ServicesContentMainContent>
                    <ServicesContentLink to="/services/production/ads">
                        Reklam Filmi
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/production/explanatory-videos">
                        Tanıtım Filmi
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/production/product-videos">
                        Ürün Tanıtım Videoları 
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/production/product-photos">
                         Ürün Görsel Çekimi
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/production/kamu-spotu">
                         Kamu Spotu
                    </ServicesContentLink>
                </ServicesContentMainContent>
            </ServicesContentBox>
            <ServicesContentBox>
                <ServicesContentBoxHeading>
                     Animasyon
                </ServicesContentBoxHeading>
                <hr style={{width:"80%"}}/>
                <ServicesContentMainContent>
                    <ServicesContentLink to="/services/animations/animation-movie">
                        Animasyon Filmi
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/info-graphics">
                        İnfografik Videolar
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/3d-animation">
                        3D Animasyon Hizmeti 
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/3d-modeling">
                         3D Modelleme Hizmeti
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/animation-support">
                         Animasyon Desteği
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/animation-movie-cinema">
                         Animasyon Sinema Filmi
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/3d-place-design">
                         3D Mekan Hazırlama Hizmeti
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/prop-design">
                         Prop Modelleme Desteği
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/animations/3d-furniture">
                         3D Mobilya Desteği
                    </ServicesContentLink>
                </ServicesContentMainContent>
            </ServicesContentBox>
            
            <ServicesContentBox>
                <ServicesContentBoxHeading>
                    Web Tasarım
                </ServicesContentBoxHeading>
                <hr style={{width:"80%"}}/>
                <ServicesContentMainContent>
                    <ServicesContentLink to="/services/webdesign/web-design">
                        Web Yazılım Geliştirme 
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/webdesign/web-design-main">
                        Web Sitesi Tasarımı
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/webdesign/web-ui-ux-design">
                        Web UI/UX Tasarımı 
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/webdesign/web-cms">
                         İçerik yönetim sistemi (CMS)
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/webdesign/web-seo">
                         SEO Çalışması
                    </ServicesContentLink>
                </ServicesContentMainContent>
            </ServicesContentBox>
            <ServicesContentBox>
                <ServicesContentBoxHeading>
                    Sosyal Medya
                </ServicesContentBoxHeading>
                <hr style={{width:"80%"}}/>
                <ServicesContentMainContent>
                    <ServicesContentLink to="/services/social-media/managment">
                        Sosyal Medya Yönetimi 
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/social-media/content-managment">
                        Sosyal Medya için Görsel ve İçerik Oluşturma
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/social-media/reporting">
                        Sosyal Medya için Raporlama
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/social-media/account-managment">
                         Sosyal Medya Hesap Yönetimi
                    </ServicesContentLink>
                    <ServicesContentLink to="/services/social-media/youtube-content">
                         Youtube İçerik Videoları
                    </ServicesContentLink>
                    <ServicesContentLink  to="/services/social-media/sponsored-ads">
                         Sponsorlu Reklam Yönetimi
                    </ServicesContentLink>
                </ServicesContentMainContent>
            </ServicesContentBox>

        </ServicesContnetWrapper>
    </ServiceContentContainer>
  )
}

export default ServicesContent