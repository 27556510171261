import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const LogoDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Logo tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Logo tasarımı, bir şirketin kimliğini ve marka bilinirliğini oluşturan bir görsel öğedir. Logo tasarımı, bir
şirketin hedef kitlesine doğru bir şekilde iletmek için önemlidir ve şirketin tüm kurumsal iletişim
araçlarında kullanılır.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default LogoDesign