import React, {useState} from 'react'
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    GeneralButtonHero
} from "./HeroElements"
import Video from '../../assets/video/bann.mp4'
import { LogoInText } from '../General/generaldesign'
import Logo from '../../assets/images/logo.png'

const HeroSection = () => {
    const [hover, setHover] = useState(false) 

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src="https://yapimevi.s3.us-east-2.amazonaws.com/bann.mp4" type='video/mp4'>
            </VideoBg>
            </HeroBg>
            <HeroContent>
                <HeroH1>Güçlü Markaların Medya Ajansı</HeroH1>
                <HeroBtnWrapper>
                    <GeneralButtonHero to="/contact" onMouseEnter={onHover} onMouseLeave={onHover}  primary={true}>
                        Teklif Al {hover ? <ArrowForward/> : <ArrowRight/>}
                    </GeneralButtonHero>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
