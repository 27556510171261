import React from 'react'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import ProductionHero from '../../subcomponents/ProductionPagesHero'
import styled from 'styled-components'

export const ServicesSubPagesContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`

const AdMovie = () => {
  return (
    <div>
        <ProductionHero/>
        <ServicesSubPagesContentContainer>
        <h1 style={{marginTop:"45px"}}>Reklam Filmi</h1>
        <ContentHolder2>
            <ContentWrapper>
            Reklam filmi, firmanızın ürün ve hizmetlerini geniş bir kitleye ulaştırmak için etkili bir yöntemdir.
            Ayrıca, potansiyel müşterilerinizin firmanız hakkında daha iyi bir fikir edinmelerine yardımcı olur.
            Bunun yanı sıra, reklam filmi sayesinde firmanızın marka bilinirliğini arttırabilir ve mevcut
            müşterilerinizle iletişiminizi güçlendirebilirsiniz.
            </ContentWrapper>
        </ContentHolder2>
        </ServicesSubPagesContentContainer>
        <Footer/>

    </div>
  )
}

export default AdMovie