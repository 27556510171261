import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const ThreeDAnimation = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>3D animasyon hizmeti</h1>
      <ContentHolder2>
          <ContentWrapper>
            3D animasyon hizmeti alarak, profesyonel bir ekip tarafından yapılan kaliteli bir animasyon elde
            edebilir. Böylece, müşterinin ihtiyaçlarına uygun bir animasyon yapılabilir ve bu sayede marka
            mesajını yeni nesil medya aracılığı ile etkili şekilde iletebilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default ThreeDAnimation
