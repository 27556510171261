import Abalioglu from "../../assets/referances/abalioglu.png"
import Akm from "../../assets/referances/akm.png"
import Ankaray from "../../assets/referances/ankaray.png"
import Ato from "../../assets/referances/ato.png"
import Danistay from "../../assets/referances/danistay.png"
import Denizli from "../../assets/referances/denizlib.png"
import Gazi from "../../assets/referances/gazi.png"
import IBB from "../../assets/referances/ibb.png"
import Isbir from "../../assets/referances/isbir.png"
import Koru from "../../assets/referances/koru.png"
import Kosgeb from "../../assets/referances/kosgeb.png"
import Kultur from "../../assets/referances/kultur.png"

import MEB from "../../assets/referances/mb.png"
import NTV from "../../assets/referances/ntv.png"
import PO from "../../assets/referances/po.png"
import Raps from "../../assets/referances/raps.png"
import Star from "../../assets/referances/star.png"
import Sekerbank from "../../assets/referances/sekerbank.png"
import Tavp from "../../assets/referances/tapv.png"
import Tarim from "../../assets/referances/tarim.png"
import Tbmm from "../../assets/referances/tbmm.png"
import Tema from "../../assets/referances/tema.png"
import Tgrt from "../../assets/referances/tgrt.png"
import Tobb from "../../assets/referances/tobb.png"
import TrtAvaz from "../../assets/referances/trtavaz.png"
import TrtBelgesel from "../../assets/referances/trtbelgesel.png"
import TrtHaber from "../../assets/referances/trthaber.png"
import Unicef from "../../assets/referances/unicef.png"
import VakifBank from "../../assets/referances/vakif.png"
import YapiKredi from "../../assets/referances/yapikredi.png"
import Ziraat from "../../assets/referances/ziraat.png"



export const ReferancesData = [
    {
        id: 1,
        img: Abalioglu,
        name: "Abalıoğlu"
    },
    {
        id: 2,
        img: Akm,
        name: "Atatürk Kültür Merkezi"
    },
    {
        id: 3,
        img: Ankaray,
        name: "Ankaray"
    },
    {
        id: 4,
        img: Ato,
        name: "Ankara Ticaret Odası"
    },
    {
        id: 5,
        img: Danistay,
        name: "Danıştay"
    },
    {
        id: 6,
        img: Denizli,
        name: "Denizli Büyük Şehir Belediyesi"
    },
    {
        id: 7,
        img: Gazi,
        name: "Gazi Üniversitesi"
    },
    {
        id: 8,
        img: IBB,
        name: "İstanbul Büyük Şehir Belediyesi"
    },
    {
        id: 9,
        img: Isbir,
        name: "İşbir"
    },
    {
        id: 10,
        img: Koru,
        name: "Koru Hastanesi"
    },
    {
        id: 11,
        img: Kosgeb,
        name: "KOSGEB"
    },
    {
        id: 12,
        img: Kultur,
        name: "Kültür Bakanlığı"
    },
    {
        id: 13,
        img: MEB,
        name: "Milli Eğitim Bakanlığı"
    },
    {
        id: 14,
        img: NTV,
        name: "NTV"
    },
    {
        id: 15,
        img: PO,
        name: "Petrol Ofisi"
    },
    {
        id: 16,
        img: Raps,
        name: "Rapsodi Mobilya"
    },
    {
        id: 17,
        img: Star,
        name: "Star TV"
    },
    {
        id: 18,
        img: Sekerbank,
        name: "Şekerbank"
    },
    {
        id: 19,
        img: Tavp,
        name: "TAPV"
    },
    {
        id: 20,
        img: Tarim,
        name: "Tarım Bakanlığı"
    },
    {
        id: 21,
        img: Tbmm,
        name: "Türkiye Büyük Millet Meclisi"
    },
    // {
    //     id: 22,
    //     img: Tema,
    //     name: "Tema Vakfı"
    // },
    {
        id: 23,
        img: Tgrt,
        name: "TGRT"
    },
    {
        id: 24,
        img: Tobb,
        name: "Türkiye Odalar Borsalar Birliği"
    },
    {
        id: 25,
        img: TrtAvaz,
        name: "TRT Avaz"
    },
    {
        id: 26,
        img: TrtBelgesel,
        name: "TRT Belgesel"
    },
    {
        id: 27,
        img: TrtHaber,
        name: "TRT Haber"
    },
    {
        id: 28,
        img: Unicef,
        name: "UNICEF"
    },
    {
        id: 29,
        img: VakifBank,
        name: "VakıfBank"
    },
    {
        id: 30,
        img: YapiKredi,
        name: "YapıKredi"
    },
    {
        id: 31,
        img: Ziraat,
        name: "Ziraat Bankası"
    },
]