import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../subcomponents/Footer'
import Navbar from '../subcomponents/Navbar'
import SidebarElements from '../subcomponents/Sidebar'

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        document.title = "Yapım Evi Prodüksiyon"
    }, [])
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  return (
    <React.Fragment>
        <SidebarElements isOpen={isOpen}  toggle={toggle}/>
        <Navbar toggle={toggle} />
        <Outlet/>
        {/* <Footer/> */}
    </React.Fragment>
  )
}

export default Layout