import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
//import {Link} from 'react-scroll'
export const Nav = styled.nav `
    background: rgba(0,0,0,0.85);
    backdrop-filter: blur(10px);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1600px;
`

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
`

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 30%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 50px;
`
export const NavDropDown = styled.div`
    background:rgba(0,0,0,0.85);
    min-height:100px;
    display:flex;
    flex-direction:column;
`
export const NavDropDownItem = styled(LinkR)`
color:  #fff;
display: flex;
align-items: center;
text-decoration: none;
height: 100%;
cursor: pointer;
padding:10px
`
export const NavDropDownSub = styled.div`
background:rgba(0,0,0,0.85);
color:#fff;
min-height:100px;
display:flex;
position:absolute;
flex-direction:column;
`

export const NavLinks = styled(LinkR)`
    color:  rgba(255,255,255,.85);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &:hover{
        color: #fff;
    }
    &.active{
        border-bottom: 3px solid #fff;
    }

`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    gap:5px;
    @media screen and (max-width: 768px){
        display: none
    }
`

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background-color: #01bf71;
    white-space: nowrap;
    padding: 8px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        backgroun: #fff;
        color: #010606

    }
`
export const NavBtnLink2 = styled.a`
    border-radius: 50px;
    background-color: white;
    white-space: nowrap;
    padding: 5px 10px;
    color: #000;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: rgba(255,255,255,.85);
        color: #000

    }
`
