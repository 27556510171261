import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const ThreeDModeling = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>3D modelleme hizmeti</h1>
      <ContentHolder2>
          <ContentWrapper>
          3D modelleme hizmeti, bir nesnenin veya ortamın bilgisayar ortamında üç boyutlu bir modelinin
            oluşturulmasını sağlar. Bu hizmet sayesinde, bir nesne veya ortamın gerçek hayattaki görünümüne
            benzer bir modeli yaratılabilir ve bu model üzerinde değişiklikler yapılarak farklı senaryolarda
            kullanılabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default ThreeDModeling
