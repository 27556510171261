import styled from "styled-components"

export const ImgLogo = styled.img`
    height:20px;
    object-fit: contain;
`
export const LogoInText = styled.img`
    height:50px;
    object-fit: contain;
    margin-bottom: 15px;

    @media screen and (max-width:768px){
        height: 30px;
    }
    
    @media screen and (max-width:468px){
        height: 45px;
        margin-bottom: 25px;
        width: 80%;
    }

`