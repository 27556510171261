import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const LeafletDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Broşür tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Broşür tasarımı, bir firmanın ürünlerini ve hizmetlerini tanıtmak için kullanılan bir reklam aracıdır.
Broşürler, genellikle perakende mağazalarında, fuar ve sergilerde veya internet sitelerinde dağıtılır ve
müşterilere ürünler hakkında bilgi edinme imkanı sağlar. Broşür tasarımı, ürünlerin görsel olarak
düzenlenmesi, metinlerin yazılması ve ürünlerin tanıtılması gibi farklı aşamaları içerir.Broşür tasarımı,
ürünlerin ve hizmetlerin özelliklerini anlatan kısa ve öz bir reklam aracıdır.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default LeafletDesign


