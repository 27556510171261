import React from 'react'
import SocialMediaPagesHero from '../../subcomponents/SocialMediaPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const SmSponsoredAds = () => {
  return (
    <div>
        <SocialMediaPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}> Sponsorlu reklam yönetimi</h1>
      <ContentHolder2>
          <ContentWrapper>
          Sponsorlu reklam yönetimi, marka veya kuruluşun, belli bir hedef kitleye ulaşmasını sağlamak
amacıyla, ödeme yaparak yayınlanan reklamların yönetimini ifade eder. Bu nedenle, sponsorlu
reklam yönetimi aşamasında, marka veya kuruluşun hedef kitleye ulaşmasını sağlayacak reklamlar
oluşturulur ve bu reklamlar ödeme yapılarak yayınlanır. Ayrıca, sponsorlu reklam yönetimi
aşamasında, marka veya kuruluşun hedef kitleye ulaşmasını ve onların dikkatini çekmesini sağlayacak
görseller de oluşturulur ve bu görseller de ödeme yapılarak yayınlanır.
<br/>
<br/>
İçerik ve görsellerin fotoğraf ve video çekimi, marka veya kuruluşun hedef kitleye ulaşmasını
sağlamak amacıyla, çekilen fotoğraf ve videoların yönetimi anlamına gelir. Bu nedenle, içerik ve
görsellerin fotoğraf ve video çekimi aşamasında, marka veya kuruluşun hedef kitleye ulaşmasını
sağlayacak fotoğraflar ve videolar çekilir. Ayrıca, içerik ve görsellerin fotoğraf ve video çekimi
aşamasında, fotoğraflar ve videoların hedef kitleye ulaşmasını sağlayacak platformlar ve kanallar da
belirlenir ve fotoğraflar ve videolar bu platformlarda ve kanallarda yayınlanır. Bu sayede, marka veya
kuruluşun hedeflerine ulaşması daha etkin bir şekilde gerçekleştirilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default SmSponsoredAds
