import './App.css';
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom"
import Layout from './components/layout';
import HomePage from './components/pages/HomePage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Contact from './components/pages/Contact';
import AboutUs from './components/pages/AboutUs';
import Services from './components/pages/Services';
import AdMovie from './components/pages/ProductionPages/AdMovie';
import AnimationMovie from './components/pages/AnimationPages';
import BrandIdentity from './components/pages/GraphiDesignPages/BrandIdentity';
import WebDesign from './components/pages/WebDesignPages/webdesign';
import Smmanagement from './components/pages/SocialMediaPages/smmanagement';
import ExpMovie from './components/pages/ProductionPages/ExpMovie';
import ProductMovie from './components/pages/ProductionPages/ProductMovie';
import ProductPhoto from './components/pages/ProductionPages/ProductPhoto';
import KamuSpotu from './components/pages/ProductionPages/KamuSpotu';
import InfoGraphics from './components/pages/AnimationPages/InfoGraphics';
import ThreeDAnimation from './components/pages/AnimationPages/ThreeDAnimation';
import ThreeDModeling from './components/pages/AnimationPages/ThreeDModeling';
import AnimationSupport from './components/pages/AnimationPages/AnimationSupport';
import AnimationMovieCinema from './components/pages/AnimationPages/AnimationMovie';
import ThreeDPlaceDesign from './components/pages/AnimationPages/ThreeDPlaceDesign';
import PropDesign from './components/pages/AnimationPages/PropDesign';
import ThreeDFurniture from './components/pages/AnimationPages/ThreeDFurniture';
import CatalogDesign from './components/pages/GraphiDesignPages/CatalogDesign';
import LeafletDesign from './components/pages/GraphiDesignPages/LeafletDesign';
import BillBoardDesign from './components/pages/GraphiDesignPages/BillBoardDesign';
import JournalDesign from './components/pages/GraphiDesignPages/JournalDesign';
import FlyerDesign from './components/pages/GraphiDesignPages/FlyerDesign';
import BannerDesign from './components/pages/GraphiDesignPages/BannerDesign';
import VehicleCoverDesign from './components/pages/GraphiDesignPages/VehicleCoverDesign';
import BrandIdentityDesign from './components/pages/GraphiDesignPages/BrandIdentityDesign';
import LogoDesign from './components/pages/GraphiDesignPages/LogoDesign';
import BannerEngDesign from './components/pages/GraphiDesignPages/BannerEngDesign';
import PosterDesign from './components/pages/GraphiDesignPages/PosterDesign';
import WebDesignMain from './components/pages/WebDesignPages/WebDesignMain';
import WebUIUXMain from './components/pages/WebDesignPages/WebUIUXDesign';
import WebCMS from './components/pages/WebDesignPages/WebCMS';
import WebSEO from './components/pages/WebDesignPages/WebSEO';
import SmContentManagement from './components/pages/SocialMediaPages/SmContentManagment';
import SmReporting from './components/pages/SocialMediaPages/SmReporting';
import SmAccountManagment from './components/pages/SocialMediaPages/SmAccountManagment';
import SmYoutubeContent from './components/pages/SocialMediaPages/SmYoutubeContent';
import SmSponsoredAds from './components/pages/SocialMediaPages/SmSponsoredAds';
import ContactV2 from './components/pages/Contact/ContactV2';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/contact" element={<ContactV2/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/services/production/ads" element={<AdMovie/>}/>
          <Route path="/services/production/explanatory-videos" element={<ExpMovie/>}/>
          <Route path="/services/production/product-videos" element={<ProductMovie/>}/>
          <Route path="/services/production/product-photos" element={<ProductPhoto/>}/>
          <Route path="/services/production/kamu-spotu" element={<KamuSpotu/>}/>
          <Route path="/services/animations/animation-movie" element={<AnimationMovie/>}/>
          <Route path="/services/animations/info-graphics" element={<InfoGraphics/>}/>
          <Route path="/services/animations/3d-animation" element={<ThreeDAnimation/>}/>
          <Route path="/services/animations/3d-modeling" element={<ThreeDModeling/>}/>
          <Route path="/services/animations/animation-support" element={<AnimationSupport/>}/>
          <Route path="/services/animations/animation-movie-cinema" element={<AnimationMovieCinema/>}/>
          <Route path="/services/animations/3d-place-design" element={<ThreeDPlaceDesign/>}/>
          <Route path="/services/animations/prop-design" element={<PropDesign/>}/>
          <Route path="/services/animations/3d-furniture" element={<ThreeDFurniture/>}/>
          <Route path="/services/graphic-design/brand-identity" element={<BrandIdentity/>}/>
          <Route path="/services/graphic-design/catalog-design" element={<CatalogDesign/>}/>
          <Route path="/services/graphic-design/leaflet-design" element={<LeafletDesign/>}/>
          <Route path="/services/graphic-design/billboard-design" element={<BillBoardDesign/>}/>
          <Route path="/services/graphic-design/journal-design" element={<JournalDesign/>}/>
          <Route path="/services/graphic-design/flyer-design" element={<FlyerDesign/>}/>
          <Route path="/services/graphic-design/banner-design" element={<BannerDesign/>}/>
          <Route path="/services/graphic-design/vehicle-cover-design" element={<VehicleCoverDesign/>}/>
          <Route path="/services/graphic-design/brand-identity-design" element={<BrandIdentityDesign/>}/>
          <Route path="/services/graphic-design/logo-design" element={<LogoDesign/>}/>
          <Route path="/services/graphic-design/banner-main-design" element={<BannerEngDesign/>}/>
          <Route path="/services/graphic-design/poster-design" element={<PosterDesign/>}/>
          <Route path="/services/webdesign/web-design" element={<WebDesign/>}/>
          <Route path="/services/webdesign/web-design-main" element={<WebDesignMain/>}/>
          <Route path="/services/webdesign/web-ui-ux-design" element={<WebUIUXMain/>}/>
          <Route path="/services/webdesign/web-cms" element={<WebCMS/>}/>
          <Route path="/services/webdesign/web-seo" element={<WebSEO/>}/>
          <Route path="/services/social-media/managment" element={<Smmanagement/>}/>
          <Route path="/services/social-media/content-managment" element={<SmContentManagement/>}/>
          <Route path="/services/social-media/reporting" element={<SmReporting/>}/>
          <Route path="/services/social-media/account-managment" element={<SmAccountManagment/>}/>
          <Route path="/services/social-media/youtube-content" element={<SmYoutubeContent/>}/>
          <Route path="/services/social-media/sponsored-ads" element={<SmSponsoredAds/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
