import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const FlyerDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Flyer tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Flyer tasarımı, bir firmanın ürünlerini ve hizmetlerini tanıtmak için kullanılan küçük bir reklam
aracıdır. Flyerler, genellikle perakende mağazalarında, fuar ve sergilerde veya internet sitelerinde
dağıtılır ve müşterilere ürünler hakkında bilgi edinme imkanı sağlar. Flyer tasarımı, ürünlerin görsel
olarak düzenlenmesi, metinlerin yazılması ve ürünlerin tanıtılması gibi farklı aşamaları içerir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default FlyerDesign


