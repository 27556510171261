import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const BrandIdentity = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Kurumsal kimlik hizmeti</h1>
      <ContentHolder2>
          <ContentWrapper>
          Kurumsal kimlik hizmeti, bir kuruluş veya şirket için gerekli olan tüm tasarım ve grafik çalışmalarının
          yapılmasını sağlar. Bu hizmet sayesinde, bir kuruluş veya şirket için logonun, kartvizitlerin,
          broşürlerin, afişlerin ve benzeri tüm tasarım çalışmalarının profesyonel bir ekip tarafından markanıza
          veya firmanıza özgün şekilde hazırlanmasıdır. Ayrıca, kurumsal kimlik hizmeti sayesinde bir kuruluş
          veya şirket için bir kimlik oluşturulur ve bu sayede kuruluş veya şirketin marka imajı geliştirilir. Bu
          sayede, Prestijli, özgün ve kurumsal kimlik ile beraber markanız veya firmanız değer kazanır.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default BrandIdentity