import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const VehicleCoverDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Araç giydirme tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Araç giydirme tasarımı, bir aracın dış görünüşünün değiştirilmesi ve marka bilinirliğini arttırmak için
yapılan tasarım çalışmasıdır. Araç giydirme tasarımı, araçların dış cephelerine yerleştirilen özel
baskılar ve aksesuarlarla yapılır ve araçların görünüşünü değiştirir. Araç giydirme tasarımı, araçların
dış görünüşünü değiştirirken, aynı zamanda marka bilinirliğini de artırır ve müşterilerin dikkatini
çeker.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default VehicleCoverDesign


