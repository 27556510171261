import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const BrandIdentityDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Kurumsal kimlik tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Kurumsal kimlik tasarımı, bir firmanın tüm kurumsal iletişim araçlarının tasarımını ve düzenlenmesini
kapsayan bir süreçtir. Kurumsal kimlik tasarımı, bir şirketin logolarını, renklerini, tipografik öğelerini,
kartvizitlerini, broşürlerini ve diğer tüm kurumsal iletişim araçlarını içerir. Kurumsal kimlik tasarımı,
bir şirketin kimliğini ve marka bilinirliğini oluşturan önemli bir etkendir ve şirketin hedef kitlesine
doğru bir şekilde iletmek için önemlidir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default BrandIdentityDesign


