import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const PropDesign = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Prop modelleme desteği</h1>
      <ContentHolder2>
          <ContentWrapper>
          Prop modelleme desteği hazırlama hizmeti, bir film, TV dizisi veya oyun gibi projeler için gereken
            eşyaların (prop) bilgisayar ortamında üç boyutlu modellerinin oluşturulmasını sağlar. Bu sayede,
            eşyaların gerçek hayattaki görünümüne benzer modeller oluşturulabilir ve bu modeller üzerinde
            değişiklikler yaparak projelerde kullanılabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default PropDesign
