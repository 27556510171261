import React from 'react'
import SocialMediaPagesHero from '../../subcomponents/SocialMediaPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const SmYoutubeContent = () => {
  return (
    <div>
        <SocialMediaPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Youtube içerik videoları</h1>
      <ContentHolder2>
          <ContentWrapper>
          Profesyonel bir prodüksiyon şirketi ile çalışarak kaliteli Youtube içerik videoları yapmanız, Youtube&#39;da
popüler olmanıza yardımcı olabilir ve hedefinize giden yolda daha emin adımlarla yürümenizi sağlar.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default SmYoutubeContent
