import React from 'react'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import ProductionHero from '../../subcomponents/ProductionPagesHero'
import styled from 'styled-components'
import { ServicesSubPagesContentContainer } from './AdMovie'
const KamuSpotu = () => {
  return (
    <div>
    <ProductionHero/>
    <ServicesSubPagesContentContainer>
    <h1 style={{marginTop:"45px"}}>Kamu Spotu</h1>
    <ContentHolder2>
        <ContentWrapper>
        Profesyonel bir prodüksiyon şirketi ile çalışarak kaliteli kamu spotları yapmanız, kamu kurum ve
kuruluşlarının hedef kitlesine ulaşmalarını kolaylaştırabilir. Ayrıca, kamu spotları sayesinde kamu
kurum ve kuruluşlarının amaç ve faaliyetlerini daha iyi anlatabilir ve toplumda farkındalık
yaratabilirsiniz.
        </ContentWrapper>
    </ContentHolder2>
    </ServicesSubPagesContentContainer>
    <Footer/>
    </div>
  )
}

export default KamuSpotu