import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const AnimationMovie = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Animasyon filmi</h1>
      <ContentHolder2>
          <ContentWrapper>
          Animasyon filmi, hareketli resimler veya nesneler aracılığıyla anlatılan bir hikâyeyi ifade etmek için
          kullanılan bir tür filmdir. Profesyonel bir prodüksiyon şirketi ile çalışarak kaliteli animasyon filmleri
          yapmanız, firmanızın hedef kitlesine daha etkili bir şekilde ulaşmasına yardımcı olabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default AnimationMovie