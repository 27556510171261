import React from 'react'
import { FaPlay } from 'react-icons/fa'
import styled from "styled-components"
import { GeneralButtonHero } from '../HeroSection/HeroElements'
import VideoPlaceholder from "../../assets/images/video_placeholder.png"
export const HomeFirstInfoContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:90px;
    margin-bottom:40px;
`

export const HomeFirstInfoWrapper = styled.div`
    width: 100%;
    max-width: 650px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

`

export const HomeFirstInfoHeading = styled.h2`
    text-align:center;
    font-size: 40px;
    @media screen and (max-width:768px){
        font-size: 35px;
    }
    @media screen and (max-width:484px){
        font-size: 30px;
    }
`
export const HomeInfoVideoMiniClip = styled.img`
    display: block;
    position: relative;
    border: 10px solid #000;
    box-shadow: 20px 20px 50px rgb(0 0 0 / 40%);
    object-fit:contain;
    max-width: 650px;
    @media screen and (max-width:768px){
        max-width: 450px;
    }
    @media screen and (max-width:484px){
        max-width: 350px;
    }

`

export const HomeMiniClipContainer = styled.div`
    position: relative;
    max-width: 400px;
    justify-content:center;
    display:flex;

`
export const HomeFirstInfoDetail = styled.h3`
    font-size: 30px;
    text-align:center;
    max-width:600px;
`

const HomeFirstInfo = () => {
  return (
    <HomeFirstInfoContainer>
        <HomeFirstInfoWrapper>
            <HomeFirstInfoHeading>
                Yapım Evi, markalara yüksek kalite standartlarında prodüksiyon hizmetleri sunmaktadır.
            </HomeFirstInfoHeading>
            <HomeMiniClipContainer>
                <HomeInfoVideoMiniClip src={VideoPlaceholder} alt="Video prodüksiyon hizmetleri" />
                <GeneralButtonHero primary={true} style={{position:"absolute", left:"30%", top:"50%"}}>
                        Video İzle &nbsp; <FaPlay style={{fontSize:"10px"}}/> 
                </GeneralButtonHero>
            </HomeMiniClipContainer>
            <HomeFirstInfoDetail>
                Geçmişten gelen tecrübelerle markanızı geleceğe taşıyoruz.
            </HomeFirstInfoDetail>
        </HomeFirstInfoWrapper>
    </HomeFirstInfoContainer>
  )
}

export default HomeFirstInfo