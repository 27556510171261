import React from 'react'
import { FaLocationArrow, FaMailBulk, FaPhone, FaWhatsapp } from 'react-icons/fa'
import styled from "styled-components"
import Footer from '../../subcomponents/Footer'
import HeroSectionContact from '../../subcomponents/HeroSectionConcact'

export const ContactPageContainer = styled.div`
    width: 100%;
    calc(100vh-10px);
`
export const ContactPageWrapper = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    flex-direction:column;
    calc(100vh-10px);
`
export const GreyBackgroundSize = styled.div`
    background:#ff3636;
    color:white;
    display:flex;
    height: 48vh;
    flex-direction:column;
    justify-content:center;
    align-items:center
`
export const TextHolder = styled.div`
`
export const ContactText = styled.h2`
font-size:24px;
@media screen and (max-width:458px){
        font-size:18px
}
`

const Contact = () => {
  return (
    <ContactPageContainer>
        <ContactPageWrapper>
            <GreyBackgroundSize>
                <h1>Bize Ulaşın</h1>
                <TextHolder>
                <ContactText>
                   <FaLocationArrow/> 1403. Cd. No 10/10 Odabaşı Apt. Balgat Ankara
                </ContactText>
                <ContactText>
                    <FaPhone/> +90 312 431 0705
                </ContactText>
                <ContactText>
                    <FaWhatsapp/> +90 539 585 2078
                </ContactText>
                <ContactText>
                    <FaMailBulk/> info@yapimeviproduksiyon.com
                </ContactText>
                </TextHolder>
            </GreyBackgroundSize>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d765.1134349045877!2d32.821522729235596!3d39.908861027430056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f45d647debb%3A0x507c1cb080f6caa9!2sBalgat%2C%201403.%20Sk.%20No%3A10%2F10%2C%2006520%20%C3%87ankaya%2FAnkara!5e0!3m2!1sen!2str!4v1668380670905!5m2!1sen!2str"style={{border:0, width:"100%", height:"48vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </ContactPageWrapper>
        <Footer/>
    </ContactPageContainer>
  )
}

export default Contact