import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const AnimationMovieCinema = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Animasyon sinema filmi</h1>
      <ContentHolder2>
          <ContentWrapper>
            Animasyon sinema filmi, animasyon teknikleri kullanılarak çekilmiş ve sunulmuş bir sinema filmidir.
            Bu tür filmlerde, karakterler ve ortamlar bilgisayar ortamında oluşturulur ve hareket ettirilerek bir
            hikaye anlatılır. Animasyon sinema filmleri, çocuklar ve yetişkinler arasında eğlenceli ve etkileyici bir
            şekilde izlenir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default AnimationMovieCinema
