import React from 'react'
import AnimationPagesHero from '../../subcomponents/AnimationPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'


const AnimationSupport = () => {
  return (
    <div>
      <AnimationPagesHero/>
      <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Animasyon desteği</h1>
      <ContentHolder2>
          <ContentWrapper>
            Animasyon desteği, bir görsel anlatım için gereken teknik ve tasarım desteğini sağlamak için verilen
            bir hizmettir. Bu destek sayesinde, bir konsept veya fikrin animasyon yoluyla etkili ve kaliteli şekilde
            anlatılmasına yardımcı olur. İzleyicilerin dikkatini çekmek ve onları etkilemek daha kolay hale gelir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default AnimationSupport
