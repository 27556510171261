import React from 'react'
import Footer from '../../subcomponents/Footer'
import ServicesContent from '../../subcomponents/ServicesContent'
import ServicesHero from '../../subcomponents/ServicesHero'

const Services = () => {
  return (
    <div>
        <ServicesHero/>
        <ServicesContent/>
        <Footer/>
    </div>
  )
}

export default Services