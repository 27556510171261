import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const BannerDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Afiş tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Afiş tasarımı, bir firmanın ürünlerini ve hizmetlerini tanıtmak için kullanılan bir reklam aracıdır.
Afişler, genellikle sokaklarda, otobüs duraklarında veya diğer yüksek trafik alanlarda asılır ve
müşterilere ürünler hakkında bilgi edinme imkanı sağlar.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default BannerDesign


