import React from 'react'
import styled from "styled-components"
import { ReferancesData } from './data'


export const  ReferencesPageContainer = styled.div`
    width: 100%;

`
export const ReferancesPageWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`
export const ReferancesPageGrid = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content:center;
    gap:15px;
    align-items:center;
    @media screen and (max-width:964px){
        grid-template-columns: 1fr 1fr 1fr;

    }
    @media screen and (max-width:666px){
        grid-template-columns: 1fr 1fr ;

    }
    @media screen and (max-width:366px){
        grid-template-columns: 1fr ;

    }
`

export const ReferancesPageGridItem = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`
export const ReferancesPageGridItemImage  = styled.img`
    object-fit:contain;
    width: 150px;
`

export const ReferancesPageGridName = styled.p`
    font-weight:600;
    font-size:15px;
    text-align:center;
`


const ReferancesComponent = () => {
  return (
    <ReferencesPageContainer>
        <ReferancesPageWrapper>
            <h1>Referanslarımız</h1>
            <ReferancesPageGrid>
            {ReferancesData?.map((e) => (
                <ReferancesPageGridItem key={e.id}>
                    <ReferancesPageGridItemImage src={e.img} alt="referans" />
                    <ReferancesPageGridName>{e.name}</ReferancesPageGridName>
                </ReferancesPageGridItem>
                 ))}
            </ReferancesPageGrid>
        </ReferancesPageWrapper>
    </ReferencesPageContainer>
  )
}

export default ReferancesComponent