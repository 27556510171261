 import React, {useEffect, useState} from 'react'
 import {FaBars, FaFlag} from 'react-icons/fa'
 import {
     Nav, 
     NavbarContainer, 
     NavLogo, 
     MobileIcon, 
     NavMenu, 
     NavItem, 
     NavLinks,
     NavBtn,
     NavBtnLink2,
     NavDropDown,
     NavDropDownItem,
     NavDropDownSub
} from './NavbarElements'
import Logo from '../../assets/images/white_logo.png'
import { ImgLogo } from '../General/generaldesign'
import Turkey from "../../assets/images/turkey.png"
import Us from "../../assets/images/us.png"


 const Navbar = ({toggle}) => {
        const [dis, setDis] = useState(false)
        const [disSub1, setDisSub1] = useState(false)
        const [disSub2, setDisSub2] = useState(false)
        const [disSub3, setDisSub3] = useState(false)
        const [disSub4, setDisSub4] = useState(false)
        const [disSub5, setDisSub5] = useState(false)

       
        var pos1X = document.getElementById('nav1')?.offsetLeft
        var pos1Y = document.getElementById('nav1')?.offsetTop
        var pos2X = document.getElementById('nav2')?.offsetLeft
        var pos2Y = document.getElementById('nav2')?.offsetTop
        var pos3X = document.getElementById('nav3')?.offsetLeft
        var pos3Y = document.getElementById('nav3')?.offsetTop
        var pos4X = document.getElementById('nav4')?.offsetLeft
        var pos4Y = document.getElementById('nav4')?.offsetTop
        var pos5X = document.getElementById('nav5')?.offsetLeft
        var pos5Y = document.getElementById('nav5')?.offsetTop
       
     return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">
                        <ImgLogo src={Logo} alt="yapım evi"/>
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="/"> Anasayfa </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/aboutus"> Hakkımızda </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/services" onMouseEnter={() => setDis(true)} onMouseLeave={() => setDis(false)}> Hizmetlerimiz </NavLinks>
                            <NavDropDown style={{display: dis ? "flex" : "none"}} onMouseEnter={() => setDis(true)} onMouseLeave={() => setDis(false)}>
                                <NavDropDownItem id="nav1" onMouseEnter={() => setDisSub1(true)} onMouseLeave={() => setDisSub1(false)}>
                                Prodüksiyon Hizmetleri
                                </NavDropDownItem>
                                    <NavDropDownSub style={{display: disSub1 ? "flex" : "none",  left:pos1X+182, top: pos1Y}}
                                        onMouseEnter={() => {
                                            setDisSub1(true);
                                            setDis(true)
                                        }
                                        } 
                                        onMouseLeave={() => {
                                            setDisSub1(false);
                                        }
                                        } 
                                        >
                                        <NavDropDownItem to="/services/production/ads">
                                        Reklam Filmi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/production/explanatory-videos">
                                        Tanıtım Filmi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/production/product-videos">
                                        Ürün Tanıtım Videoları
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/production/product-photos">
                                        Ürün Görsel Çekimi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/production/kamu-spotu">
                                        Kamu Spotu
                                        </NavDropDownItem>
                                     
                                    </NavDropDownSub>
                                <NavDropDownItem id="nav2"  onMouseEnter={() => setDisSub2(true)} onMouseLeave={() => setDisSub2(false)}>
                                    Animasyon
                                </NavDropDownItem>
                                <NavDropDownSub style={{display: disSub2 ? "flex" : "none",  left:pos2X+182, top: pos2Y}}
                                        onMouseEnter={() => {
                                            setDisSub2(true);
                                            setDis(true)
                                        }
                                        } 
                                        onMouseLeave={() => {
                                            setDisSub2(false);
                                        }
                                        } 
                                        >
                                        <NavDropDownItem to="/services/animations/animation-movie">
                                        Animasyon Filmi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/info-graphics">{/*ekleme var buraya*/}
                                        İnfografik Videolar
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/3d-animation">
                                        3D animasyon hizmeti
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/3d-modeling">
                                        3D modelleme hizmeti
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/animation-support">
                                        Animasyon Desteği
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/animation-movie-cinema">
                                        Animasyon sinema filmi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/3d-place-design">
                                        3D mekan hazırlama hizmeti
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/prop-design">
                                        Prop modelleme desteği
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/animations/3d-furniture">
                                        3D mobilya desteği
                                        </NavDropDownItem>
                                    </NavDropDownSub>
                                <NavDropDownItem id="nav3"  onMouseEnter={() => setDisSub3(true)} onMouseLeave={() => setDisSub3(false)}>
                                    Grafik Tasarım
                                </NavDropDownItem>
                                <NavDropDownSub style={{display: disSub3 ? "flex" : "none",  left:pos3X+182, top: pos3Y}}
                                        onMouseEnter={() => {
                                            setDisSub3(true);
                                            setDis(true)
                                        }
                                        } 
                                        onMouseLeave={() => {
                                            setDisSub3(false);
                                        }
                                        } 
                                        >
                                        <NavDropDownItem  to="/services/graphic-design/brand-identity">
                                        Kurumsal kimlik hizmeti
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/catalog-design">
                                        Katalog tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/leaflet-design">
                                        Broşür tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/billboard-design">
                                        Billboard tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/journal-design">
                                        Dergi tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/flyer-design">
                                        Flyer tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/banner-design">
                                        Afiş tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/vehicle-cover-design">
                                        Araç giydirme tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/brand-identity-design">
                                        Kurumsal kimlik tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/logo-design">
                                        Logo tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/banner-main-design">
                                        Banner tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/graphic-design/poster-design">
                                        Poster tasarımı
                                        </NavDropDownItem>
                                     
                                    </NavDropDownSub>

                                <NavDropDownItem id="nav4"  onMouseEnter={() => setDisSub4(true)} onMouseLeave={() => setDisSub4(false)}>
                                    Web Tasarım
                                </NavDropDownItem>
                                <NavDropDownSub style={{display: disSub4 ? "flex" : "none",  left:pos4X+182, top: pos4Y}}
                                        onMouseEnter={() => {
                                            setDisSub4(true);
                                            setDis(true)
                                        }
                                        } 
                                        onMouseLeave={() => {
                                            setDisSub4(false);
                                        }
                                        } 
                                        >
                                        <NavDropDownItem  to="/services/webdesign/web-design">
                                        Web yazılım geliştirme
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/webdesign/web-design-main">
                                        Web sitesi tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/webdesign/web-ui-ux-design">
                                        Web UI/UX tasarımı
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/webdesign/web-cms">
                                        İçerik yönetim sistemi (CMS)
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/webdesign/web-seo">
                                        SEO çalışması
                                        </NavDropDownItem>
                                     
                                    </NavDropDownSub>
                                <NavDropDownItem id="nav5"  onMouseEnter={() => setDisSub5(true)} onMouseLeave={() => setDisSub5(false)}>
                                   Sosyal Medya
                                </NavDropDownItem>
                                <NavDropDownSub style={{display: disSub5 ? "flex" : "none",  left:pos5X+182, top: pos5Y}}
                                        onMouseEnter={() => {
                                            setDisSub5(true);
                                            setDis(true)
                                        }
                                        } 
                                        onMouseLeave={() => {
                                            setDisSub5(false);
                                        }
                                        } 
                                        >
                                        <NavDropDownItem to="/services/social-media/managment">
                                        Sosyal medya yönetimi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/social-media/content-managment">
                                        Sosyal medya için görsel ve içerik oluşturma
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/social-media/reporting">
                                        Sosyal medya için raporlama
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/social-media/account-managment">
                                        Sosyal medya hesap yönetimi
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/social-media/youtube-content">
                                        Youtube içerik videoları
                                        </NavDropDownItem>
                                        <NavDropDownItem to="/services/social-media/sponsored-ads">
                                        Sponsorlu reklam yönetimi
                                        </NavDropDownItem>
                                     
                                    </NavDropDownSub>
                            </NavDropDown>
                        </NavItem>
                
                        {/* <NavItem>
                            <NavLinks to="/pricing"> Fiyatlandırma </NavLinks>
                        </NavItem> */}
                        <NavItem>
                            <NavLinks to="/contact"> İletişim </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink2 href="/contact">Teklif Al</NavBtnLink2>
                    </NavBtn>
                </NavbarContainer>
            </Nav>  
        </>
     )
 }
 
 export default Navbar
 