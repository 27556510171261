import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const BillBoardDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Billboard tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Billboard tasarımı, afişlerin yer aldığı ve genellikle yüksek yerlerde bulunan ve kalabalık bölgelerde
kullanılan reklam panolarının tasarımıdır. Billboard tasarımı, ürünlerin ve hizmetlerin tanıtılması için
kullanılan bir reklam aracıdır ve müşterilere ürünler hakkında bilgi edinme imkanı sağlar.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default BillBoardDesign


