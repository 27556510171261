import React from 'react'
import GraphicDesignHero from '../../subcomponents/GraphicDesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const CatalogDesign = () => {
  return (
    <div>
        <GraphicDesignHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Katalog tasarımı</h1>
      <ContentHolder2>
          <ContentWrapper>
          Katalog tasarımı, ürünlerin tanıtılması için yapılan bir reklam çalışmasıdır ve ürünlerin özelliklerini,
            fiyatlarını ve diğer önemli bilgileri belirtir. Katalog tasarımı, ürünlerin görsel olarak düzenlenmesi,
            metinlerin yazılması ve ürünlerin tanıtılması gibi farklı aşamaları içerir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default CatalogDesign


