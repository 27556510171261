import React from 'react'
import AboutUSContent from '../../subcomponents/AboutUsContent'
import AboutUsHero from '../../subcomponents/AboutUsHero'
import Footer from '../../subcomponents/Footer'
import HomeAllOurVideos from '../../subcomponents/HomeAllOurVideos'
import ReferancesComponent from '../../subcomponents/ReferancesComponent'

const AboutUs = () => {
  return (
    <div>
        <AboutUsHero/>
        <AboutUSContent/>
        <HomeAllOurVideos/>
        <ReferancesComponent/>

        <Footer/>
    </div>
  )
}

export default AboutUs