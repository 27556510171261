import React from 'react'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import ProductionHero from '../../subcomponents/ProductionPagesHero'
import styled from 'styled-components'
import { ServicesSubPagesContentContainer } from './AdMovie'

const ExpMovie = () => {
  return (
    <div>
    <ProductionHero/>
    <ServicesSubPagesContentContainer>
    <h1 style={{marginTop:"45px"}}>Tanıtım Filmi</h1>
    <ContentHolder2>
        <ContentWrapper>
        Tanıtım filmi, bir ürün ya da hizmeti tanıtmak ve müşterilere pazarlayabilmek için yapılan bir tür
video hizmetidir. Bu filmler, kısa ve etkileyici bir şekilde ürün ya da markalarınızın özelliklerini anlatır
ve müşterileri bu ürün ya da hizmete ilgi duymaya teşvik eder.
<br/>
<br/>
Bir tanıtım filmi, bir ürün veya hizmet hakkında hızlı ve etkileyici bir şekilde bilgi vermek için yapılır.
Bu filmler, potansiyel müşterilerin ilgisini çekmektedir hatta marka bilinirliğini artırabilir ve mevcut
müşterilerinizle iletişiminizi güçlendirebilirsiniz. Tanıtım veya reklam filmi ürün veya hizmet hakkında
daha geniş bir kitleye ulaşmak için etkili bir yoldur. Ayrıca, bu filmler, müşterilerin satın alma kararını
hızlandırmak ve marka farkındalığını artırmak için de kullanılır. Bu nedenle, doğrudan veya dolaylı
yoldan hedef kitleye ulaştırır.
        </ContentWrapper>
    </ContentHolder2>
    </ServicesSubPagesContentContainer>
    <Footer/>

</div>
  )
}

export default ExpMovie