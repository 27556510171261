import React from 'react'
import styled from 'styled-components'

export const ContentHolder = styled.div`
    display:flex;
    min-height:50vh;
    align-items:center;
    justify-content:center;
    margin-top:15px;
    margin-bottom:15px;
`
export const ContentHolder2 = styled.div`
    display:flex;
    min-height:40vh;
    align-items:center;
    justify-content:center;
    margin-top:15px;
    margin-bottom:15px;
`

export const ContentWrapper = styled.div`
    padding: 50px;
    font-size:20px;
    max-width:50%;
    background:#f5f5f7;
    border-radius:10px;
`



const AboutUSContent = () => {
  return (
    <ContentHolder>
        <ContentWrapper>
            Yapım evi Produksiyon, 1992 yılında kurulan ve produksiyon işleriyle uğraşan bir ajansdır. Şirket, film,
            reklam, sosyal medya, animasyon filmi, kurumsal kimlik ve grafik tasarım gibi alanlarda uzmanlaşmış
            ve bu alanlarda faaliyet göstermektedir. Büyük ve güçlü markalara hizmet veren Yapım evi
            Produksiyon, müşterilerine en iyi hizmeti sunmak için kuruluşundan bu yana sektördeki tecrübesini
            ve bilgisini kullanmaktadır. Şirket, uzmanlaştığı alanlarda müşterilerine en iyi hizmeti sunmak için
            çalışmaktadır ve bu konuda önemli başarılara imza atmıştır. Yapım evi Produksiyon, müşteri
            memnuniyetini her zaman ön planda tutarak, işini en iyi şekilde yapmayı hedeflemektedir.
        </ContentWrapper>
    </ContentHolder>
  )
}

export default AboutUSContent