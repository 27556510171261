import React from 'react'
import WebdesignPagesHero from '../../subcomponents/WebdesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const WebDesign = () => {
  return (
    <div>
        <WebdesignPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Web yazılım geliştirme</h1>
      <ContentHolder2>
          <ContentWrapper>
          Web yazılım geliştirme, internet üzerinden çalışan yazılımların tasarımı, geliştirilmesi ve yönetilmesi
          sürecidir. Web yazılım geliştirme, genellikle bir web tarayıcısı üzerinden erişilebilen ve çeşitli işlevler
          gerçekleştiren uygulamaların tasarımı ve geliştirilmesine odaklanır. Web yazılım geliştirme, çeşitli
          programlama dilleri ve teknolojileri kullanılarak yapılır ve internet üzerinden çalışan uygulamaların
          yönetimini de içerir. Web yazılım geliştirme, birçok farklı amaç için kullanılabilir. Örneğin, bir e-ticaret
          sitesi oluşturmak için web yazılım geliştirme kullanılabilir veya bir sosyal medya platformu oluşturmak
          için de kullanılabilir.
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default WebDesign