import React from 'react'
import WebdesignPagesHero from '../../subcomponents/WebdesignPagesHero'
import { ContentHolder2, ContentWrapper } from '../../subcomponents/AboutUsContent'
import Footer from '../../subcomponents/Footer'
import { ServicesSubPagesContentContainer } from '../ProductionPages/AdMovie'
const WebUIUXMain = () => {
  return (
    <div>
        <WebdesignPagesHero/>
        <ServicesSubPagesContentContainer>
      <h1 style={{marginTop:"45px"}}>Web arayüzü geliştirme</h1>
      <ContentHolder2>
          <ContentWrapper>
          Web arayüzü geliştirme, bir internet sitesinin kullanıcılarının siteyi kullanırken deneyimlediği görsel
arayüzün tasarımı sürecidir. Web arayüzü geliştirme, birçok farklı aşamayı içerir ve genellikle
aşağıdaki adımları içerir:
<br/>
<br/>
<ol>1. İş gereksinimlerinin belirlenmesi: İlk olarak, web arayüzünün amacı ve hedef kitle belirlenir.
Bu aşama, web arayüzünün işlevselliğini ve tasarımını belirleyen önemli bir etkendir.</ol>
<ol>2. Kullanıcı deneyimi tasarımı: Kullanıcı deneyimi tasarımı aşamasında, web arayüzünün
kullanıcıların deneyimine nasıl uygun hale getirileceği belirlenir. Bu aşamada, web
arayüzünün nasıl kullanılacağı, hangi öğelerin kullanılacağı ve nasıl yönlendirileceği gibi
konular planlanır.</ol>
<ol>3.Görsel tasarım: Görsel tasarım aşamasında, web arayüzünün görünüşü ve tasarımı oluşturulur. Bu
aşamada, web arayüzünün renkleri, tipografisi, logo ve diğer görsel öğeleri belirlenir.</ol>
<ol>4.Kodlama: Kodlama aşamasında, web arayüzünün tasarımı ve işlevselliği gerçekleştirilebilir hale
getirilir. Bu aşamada, web arayüzünün tasarımı için gereken HTML, CSS ve JavaScript gibi kodlar
yazılır.</ol>
<ol>5.Test etme: Test etme aşamasında, web arayüzünün işlevselliği ve performansı test edilir. Bu
aşamada, web arayüzünün çeşitli tarayıcılarda nasıl göründüğü ve çalıştığı test edilir ve varsa hatalar
düzeltilir.</ol>

<ol>6. Yayınlama: Yayınlama aşamasında, web arayüzü kullanıma sunulur. Bu aşamada, web
arayüzü bir web sunucusuna yüklenir ve internete yayınlanır.</ol>
<ol>7. Yönetim: Yönetim aşamasında, web arayüzünün çalışmasını ve işlevselliğini sürdürmek için
gereken adımlar gerçekleştirilir. Bu aşamada, web arayüzünün performansı izlenir ve varsa
hatalar düzeltilir. Ayrıca, web arayüzünün güncelleştirilmesi ve yenilenmesi gibi işlemler de
bu aşamada gerçekleştirilir.</ol>
          </ContentWrapper>
      </ContentHolder2>
      </ServicesSubPagesContentContainer>
      <Footer/>
    </div>
  )
}

export default WebUIUXMain