import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import { Link } from 'react-router-dom'
export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: left;
    align-items:flex-end;
    padding : 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    margin-top:-50px;
    :before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`
export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 850px;
    position: absolute;
    padding: 8px 24px;
    margin-bottom:130px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    @media screen and (max-width: 425px){
        padding: 0px 0px;
    }
`
export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 75px;
    text-align: center;
    line-height:80px;
    @media screen and (max-width:768px){
        font-size: 40px;
    }

    @media screen and (max-width:768px){
        font-size: 32px;
    }

`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width:768px){
        font-size: 24px;
    }
    
    @media screen and (max-width:468px){
        font-size: 18px;
        padding: 0 10px;
    }

`
export const HeroBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`



export const GeneralButtonHero = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? "#fff" : "#010606")};
    white-space: nowrap;
    padding: ${({big}) => (big ? "14px 48px" : "12px 30px")};
    color: ${({primary}) => (primary ? "#000" : "#fff")};
    font-size:  ${({fontBig}) => (fontBig ? "20px" : "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    text-decoration: none;
    &:hover {
        transition: all 1 ease-in-out;
        background: ${({primary}) => (primary ? "rgba(255,255,255,.85)" : "#01BF71" )};
        color: ${({primary}) => (primary ? "#000" : "#fff")};
    }
`
